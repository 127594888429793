import * as amplitude from "@amplitude/analytics-browser";

export const initAmplitude = () => {
  amplitude.init("1c1e294b772aed17d7d423389db61a5f", {
    autocapture: { elementInteractions: true },
  });
};

export const setAmplitudeUserDevice = (installationToken) => {
  amplitude.setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
  amplitude.setUserId(userId);
};
