import { useHistory, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { AES, enc } from "crypto-js";
import cryptoConfig from "../../configs/crypto";
import * as Autenticacao from "../../services/autenticacao";
import { store } from "../../redux/store";
import * as action from "../../redux/actions";
import { GoogleLogin } from "@react-oauth/google";
import { setAmplitudeUserId } from "../../utils/amplitude";

import {
  Container,
  TextField,
  Box,
  Button,
  Grid,
  Link as LinkComp,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import Logo from "../../assets/images/logo.png";
import { useCallback, useEffect } from "react";
import ReactGA from "react-ga4";

const useStyles = makeStyles(() => ({
  logo: {
    width: "30%",
    display: "block",
    margin: "auto",
  },
  divider: {
    margin: "16px 0",
  },
  social: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
  },
}));

export default function Login() {
  if (window.location.host === "aluno.academiapedromartinez.com.br")
    ReactGA.send({
      hitType: "pageview",
      page: window.location.hash,
      title: "Login",
    });

  let history = useHistory();
  let location = useLocation();

  const classes = useStyles();

  let { from } = location.state || { from: { pathname: "/agendamento" } };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    async (params) => {
      const { email, senha, salvar } = params;

      const response = await Autenticacao.Login({ email, senha });

      if (response.token) {
        if (salvar) {
          const message = JSON.stringify({ email, senha });
          const clipper = AES.encrypt(message, cryptoConfig.secret);
          window.localStorage.setItem("clipper", clipper.toString());
        }

        store.dispatch(action.setUser(response));

        if (window.location.host === "aluno.academiapedromartinez.com.br") {
          ReactGA.event("login", {
            method: "apm-user",
          });

          setAmplitudeUserId(email);
        }

        history.replace(from);
      } else {
        window.localStorage.removeItem("clipper");
      }
    },
    [from, history]
  );

  useEffect(() => {
    (function autoLogin() {
      const clipper = window.localStorage.getItem("clipper");

      if (clipper) {
        let bytes;

        bytes = AES.decrypt(clipper, cryptoConfig.secret);

        const decrypted = bytes.toString(enc.Utf8);

        const temp = JSON.parse(decrypted);

        if (temp && temp.email && temp.senha) {
          onSubmit(temp);
        }
      }
    })();
  }, [onSubmit]);

  return (
    <Container component="main" maxWidth="xs">
      <Box mt={2}>
        <img
          alt="Academia Pedro Martinez"
          src={Logo}
          className={classes.logo}
        />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Controller
            name="email"
            type="email"
            control={control}
            rules={{
              required: "Campo Obrigatório",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Formato Inválido",
              },
            }}
            autoComplete="off"
            defaultValue=""
            render={({ field }) => {
              if (errors.email) {
                field.error = true;
                field.helperText = errors.email.message;
              }

              return (
                <TextField
                  id="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Email"
                  type="email"
                  defaultValue=""
                  required
                  aria-required
                  autoFocus
                  autoComplete="email"
                  {...field}
                />
              );
            }}
          />

          <Controller
            name="senha"
            control={control}
            rules={{ required: "Campo Obrigatório" }}
            render={({ field }) => {
              if (errors.senha) {
                field.error = true;
                field.helperText = errors.senha.message;
              }

              return (
                <TextField
                  id="password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  defaultValue=""
                  label="Senha"
                  type="password"
                  autoComplete="current-password"
                  {...field}
                />
              );
            }}
          />

          <Controller
            name="salvar"
            control={control}
            defaultValue={true}
            render={({ field }) => {
              return (
                <FormControlLabel
                  label="Salvar dados de acesso"
                  control={
                    <Checkbox
                      defaultChecked={true}
                      defaultValue={true}
                      color="primary"
                      {...field}
                    />
                  }
                />
              );
            }}
          />

          <Button
            id="bt-login"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Entrar
          </Button>
        </form>
        <Box mt={3}>
          <Grid container spacing={2}>
            <Grid item xs>
              <LinkComp
                href="#/solicitar-nova-senha/esqueci-minha-senha"
                variant="body2"
              >
                Esqueci minha senha
              </LinkComp>
            </Grid>
            <Grid item>
              <LinkComp
                href="#/solicitar-nova-senha/primeiro-acesso"
                variant="body2"
              >
                Primeiro acesso
              </LinkComp>
            </Grid>
          </Grid>
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.social}>
          <GoogleLogin
            onSuccess={async (credentialResponse) => {
              const response = await Autenticacao.LoginSocial({
                origin: "google",
                credential: credentialResponse,
              });

              if (response) {
                store.dispatch(action.setUser(response));

                if (
                  window.location.host === "aluno.academiapedromartinez.com.br"
                ) {
                  ReactGA.event("login", {
                    method: "google",
                  });
                  setAmplitudeUserId(response.email);
                }

                history.replace(from);
              } else {
                window.localStorage.removeItem("clipper");
              }
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </Box>
      </Box>
    </Container>
  );
}
