import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setAmplitudeUserId } from "../../utils/amplitude";

const PrivateRoute = ({ children, user, ...rest }) => {
  setAmplitudeUserId(user.email);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user.token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
